import React from 'react';
import { SvgIcon } from '@ui-elements';
import classNames from 'classnames';
import styles from '../index.module.scss';

const Testimonial = React.memo((props: any) => {
  const { viewMore } = props;
  const value = viewMore ? 'auto' : '60px';

  const testimonialClass = classNames({
    [styles.testimonialOverflow]: true,
    [styles.hiddenText]: !viewMore,
  });

  return (
    <div>
      <div
        className={testimonialClass}
        style={{ height: value, color: `#${props.fontColor} !important` }}
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
      {props.text.length > 173 && (
        <div className={styles.viewMoreContainer}>
          <span
            onClick={() => {
              props.handleClick();
            }}
          >
            {!viewMore ? (
              <span
                style={{
                  verticalAlign: 'text-bottom',
                  fontWeight: 'bold',
                }}
              >
                View more{' '}
                <SvgIcon
                  type="chevronDown"
                  size={1}
                  style={{
                    marginLeft: '3px',
                    verticalAlign: 'top',
                  }}
                />
              </span>
            ) : (
              <span
                style={{
                  verticalAlign: 'text-bottom',
                  fontWeight: 'bold',
                }}
              >
                View less{' '}
                <SvgIcon
                  type="chevronUp"
                  size={1}
                  style={{
                    marginLeft: '3px',
                    verticalAlign: 'top',
                  }}
                />
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
});

export default Testimonial;
