import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { Testimonial } from './components';

export const TeamSection = React.memo((props: any) => {
  return <TeamContent {...props} />;
});

const TeamContent = (props: any) => {
  const headerStyle = {
    justifyContent: props.headerAlignment || 'center',
  };

  return (
    <div
      id={props.contentId}
      className={styles.teamContainer}
      style={{
        backgroundColor: `#${props.backgroundColor.color}`,
        color: `#${props.fontColor.color}`,
      }}
    >
      <div className={styles.headerContainer} style={headerStyle}>
        <h3>{props.header}</h3>
        {props.isDisclaimerVisible && (
          <TooltipDisclaimer disclaimer={props.disclaimerContent} />
        )}
      </div>
      <div
        className={styles.copy}
        dangerouslySetInnerHTML={{ __html: props.headerContent }}
      />
      <div className={styles.profilesContainer}>
        {props.members.map((member, index) => {
          return <MemberTile member={member} key={index} />;
        })}
      </div>
    </div>
  );
};

const MemberTile = (props: any) => {
  const { member } = props;
  const [viewMore, setViewMore] = useState(false);

  const profileTileClass = classNames({
    [styles.profileTile]: true,
    [styles.active]: viewMore,
  });

  const handleClick = () => {
    setViewMore((prev) => !prev);
  };

  return (
    <div
      className={profileTileClass}
      style={{
        backgroundColor: `#${member.backgroundColor.color}`,
        color: `#${member.fontColor.color}`,
      }}
    >
      <div>
        <div>
          <img className={styles.profileImage} src={member.profilePicture} />
        </div>
        <div className={styles.profileTileContent}>
          <div className={styles.titleDetailsContainer}>
            <span className={styles.memberName}>{member.name}</span>
            <span style={{ marginLeft: '3px', marginRight: '3px' }}>|</span>
            <span>{member.title}</span>
          </div>
          <div className={styles.contactDetailsContainer}>
            {member.showContactDetails && member.mobileNumber && (
              <div>
                <SvgIcon type={member.mobileNumberIcon} size={1} />
                <span>{member.mobileNumber}</span>
              </div>
            )}
            {member.showContactDetails && member.email && (
              <div>
                <SvgIcon type={member.emailIcon} size={1} />
                <span>{member.email}</span>
              </div>
            )}
          </div>
          <Testimonial
            text={member.content}
            fontColor={member.fontColor.color}
            handleClick={handleClick}
            viewMore={viewMore}
          />
        </div>
      </div>
    </div>
  );
};
